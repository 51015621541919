import {
  basicPlanId,
  creatorPlanId,
} from "@jammable/ui-payments-feature/src/utils/subscriptions";
import { isProduction } from "./env";

/**
 * Supported currency codes in the application
 */
export type CurrencyCode = "USD" | "GBP" | "EUR" | "INR" | "IDR";

/**
 * Lowercase currency codes for API compatibility
 */
export type LowercaseCurrencyCode = "usd" | "gbp" | "eur" | "inr" | "idr";

/**
 * Available subscription plan types
 */
export type PlanType = "basic" | "creator" | "power";

/**
 * Plan names as they appear in the user interface and API
 */
export type PlanName =
  | "Jammable Basic"
  | "Jammable Creator"
  | "Jammable Power User";

/**
 * Available pricing tiers
 */
export type PriceTier =
  | "basic"
  | "basic-discount"
  | "creator"
  | "creator-discount"
  | "power";

/**
 * Currency metadata including symbols and display information
 */
export interface CurrencyMetadata {
  code: CurrencyCode;
  lowercaseCode: LowercaseCurrencyCode;
  symbol: string;
  name: string;
  symbolPosition?: "before" | "after"; // Whether the symbol appears before or after the amount
}

/**
 * Comprehensive currency configuration
 * Makes it easy to add new currencies by defining all related attributes in one place
 */
export const CURRENCIES: Record<CurrencyCode, CurrencyMetadata> = {
  USD: {
    code: "USD",
    lowercaseCode: "usd",
    symbol: "$",
    name: "US Dollar",
    symbolPosition: "before",
  },
  GBP: {
    code: "GBP",
    lowercaseCode: "gbp",
    symbol: "£",
    name: "British Pound",
    symbolPosition: "before",
  },
  EUR: {
    code: "EUR",
    lowercaseCode: "eur",
    symbol: "€",
    name: "Euro",
    symbolPosition: "before",
  },
  INR: {
    code: "INR",
    lowercaseCode: "inr",
    symbol: "₹",
    name: "Indian Rupee",
    symbolPosition: "before",
  },
  IDR: {
    code: "IDR",
    lowercaseCode: "idr",
    symbol: "Rp",
    name: "Indonesian Rupiah",
    symbolPosition: "before",
  },
};

/**
 * Get an array of all supported currency codes
 * Useful for validation and UI dropdowns
 */
export const SUPPORTED_CURRENCIES = Object.keys(CURRENCIES) as CurrencyCode[];

/**
 * Map of plan types to plan names
 * Used for displaying plan names in the UI and for API compatibility
 */
export const PLAN_NAMES: Record<PlanType, PlanName> = {
  basic: "Jammable Basic",
  creator: "Jammable Creator",
  power: "Jammable Power User",
};

/**
 * Structured mapping for plan IDs
 * Instead of accessing plan IDs by array index, we use a meaningful structure
 * that clearly shows which ID is for which plan and currency
 */
export const PLAN_IDS = {
  production: {
    basic: {
      USD_MONTHLY: basicPlanId[3], // 7.99 USD Monthly
      GBP_MONTHLY: basicPlanId[4], // 7.99 GBP Monthly
      EUR_MONTHLY: basicPlanId[5], // 7.99 EUR Monthly
      INR_MONTHLY: basicPlanId[6], // INR Monthly
      IDR_MONTHLY: basicPlanId[7], // IDR Monthly
    },
    creator: {
      USD_MONTHLY: creatorPlanId[0], // USD Monthly
      GBP_MONTHLY: creatorPlanId[2], // GBP Monthly
      EUR_MONTHLY: creatorPlanId[4], // EUR Monthly
      INR_MONTHLY: creatorPlanId[6], // INR Monthly
      IDR_MONTHLY: creatorPlanId[7], // IDR Monthly
    },
  },
  development: {
    basic: {
      USD_MONTHLY: basicPlanId[basicPlanId.length > 7 ? 7 : 0], // USD Monthly (dev)
      GBP_MONTHLY: basicPlanId[basicPlanId.length > 8 ? 8 : 0], // GBP Monthly (dev)
      EUR_MONTHLY: basicPlanId[basicPlanId.length > 9 ? 9 : 0], // EUR Monthly (dev)
      INR_MONTHLY: basicPlanId[basicPlanId.length > 10 ? 10 : 0], // INR Monthly (dev)
      IDR_MONTHLY: basicPlanId[basicPlanId.length > 11 ? 11 : 0], // IDR Monthly (dev)
    },
    creator: {
      USD_MONTHLY: creatorPlanId[creatorPlanId.length > 1 ? 1 : 0], // USD Monthly (dev)
      GBP_MONTHLY: creatorPlanId[creatorPlanId.length > 3 ? 3 : 0], // GBP Monthly (dev)
      EUR_MONTHLY: creatorPlanId[creatorPlanId.length > 5 ? 5 : 0], // EUR Monthly (dev)
      INR_MONTHLY: creatorPlanId[creatorPlanId.length > 7 ? 7 : 0], // INR Monthly (dev)
      IDR_MONTHLY: creatorPlanId[creatorPlanId.length > 8 ? 8 : 0], // IDR Monthly (dev)
    },
  },
};

/**
 * Comprehensive price ID mapping for all plans and currencies
 * This combines all the different places where price IDs are defined
 * and creates a single source of truth
 */
export const COMPLETE_PRICE_IDS = {
  production: {
    "Jammable Basic": {
      usd: "price_1Nr1q2CUwJnq3QQBxrGQFU8s",
      gbp: "price_1Nr1qBCUwJnq3QQBi6JNTOTa",
      eur: "price_1Nr1qMCUwJnq3QQBU936IGwN",
      inr: "price_1NxYHHCUwJnq3QQBe62v7xcL",
      idr: basicPlanId[7], // Using the same ID as from basicPlanId array
    },
    "Jammable Creator": {
      usd: "price_1MwYlgCUwJnq3QQBaxn8t4Jf",
      gbp: "price_1N7K8xCUwJnq3QQBHhm5Y4IU",
      eur: "price_1N7K9xCUwJnq3QQBBiH8Uk5j",
      inr: "price_1NxYHlCUwJnq3QQB0TTeBpXb",
      idr: creatorPlanId[7], // Using the same ID as from creatorPlanId array
    },
    "Jammable Power User": {
      usd: "price_1MwYn7CUwJnq3QQB6mg34Jf9",
      gbp: "price_1N7KEkCUwJnq3QQB3EmjwmBP",
      eur: "price_1N7KFDCUwJnq3QQBzn08BY8B",
      inr: "price_1NxYI3CUwJnq3QQB8MXWWU1a",
      idr: "price_1NxYI3CUwJnq3QQB8MXWWU1a", // Using INR price ID until IDR-specific one is created
    },
  },
  development: {
    "Jammable Basic": {
      usd: "price_1NVMOzCUwJnq3QQBV8JbGqKr",
      gbp: "price_1NVMPHCUwJnq3QQBaL1BZ2el",
      eur: "price_1OecYtCUwJnq3QQBHNXkDWOI",
      inr: "price_1NxYNkCUwJnq3QQBpdqyezhP",
      idr: "price_1NxYNkCUwJnq3QQBpdqyezhP", // Using INR price ID until IDR-specific one is created
    },
    "Jammable Creator": {
      usd: "price_1MvQEuCUwJnq3QQBQSWoTJBd",
      gbp: "price_1N7K0CCUwJnq3QQBLc6IUJgV",
      eur: "", // Empty in the original implementation
      inr: "price_1NxYRQCUwJnq3QQBSkZHr7e9",
      idr: "price_1NxYRQCUwJnq3QQBSkZHr7e9", // Using INR price ID until IDR-specific one is created
    },
    "Jammable Power User": {
      usd: "price_1MvQU5CUwJnq3QQBuho7gelx",
      gbp: "price_1N7KGaCUwJnq3QQBxVjM33ap",
      eur: "price_1N7KGoCUwJnq3QQBO9d5HNTH",
      inr: "price_1NxYRgCUwJnq3QQB69I92HJj",
      idr: "price_1NxYRgCUwJnq3QQB69I92HJj", // Using INR price ID until IDR-specific one is created
    },
  },
};

/**
 * Discount coupon codes for different plan types and currencies
 * Structured by environment (production/development), plan type, and currency
 */
export const DISCOUNT_CODES = {
  production: {
    basic: {
      USD: "X9iKJtVF", // 1.99
      GBP: "XPgJCcxH", // 1.99
      EUR: "IPZxKy1E", // 1.99
      INR: "Q4VGFfHD", // 1.99
      IDR: "swKEquof", // Currently using INR code for IDR until specific one is created
    },
    creator: {
      USD: "zRsoR2yg",
      GBP: "JGNYODik",
      EUR: "J3htmHI8",
      INR: "LG7KIwnf", // No specific code for INR yet
      IDR: "DusOxDcm", // No specific code for IDR yet
    },
  },
  development: {
    basic: {
      USD: "VdJl8NUz",
      GBP: "v8qRomQ2",
      EUR: "YYRoVkHW",
      INR: "rBbnNdpq",
      IDR: "rBbnNdpq", // Currently using INR code for IDR until specific one is created
    },
    creator: {
      USD: null, // No development codes defined in original implementation
      GBP: null,
      EUR: null,
      INR: null,
      IDR: null,
    },
  },
};

/**
 * Structured mapping of price IDs for each plan and currency combination
 * Makes it easy to understand which price ID corresponds to which plan/currency
 * and allows for easy addition of new currencies or plans
 */
export const PLAN_PRICE_IDS: Record<PlanType, Record<CurrencyCode, string>> = {
  basic: {
    USD: PLAN_IDS[isProduction ? "production" : "development"].basic
      .USD_MONTHLY,
    GBP: PLAN_IDS[isProduction ? "production" : "development"].basic
      .GBP_MONTHLY,
    EUR: PLAN_IDS[isProduction ? "production" : "development"].basic
      .EUR_MONTHLY,
    INR: PLAN_IDS[isProduction ? "production" : "development"].basic
      .INR_MONTHLY,
    IDR: PLAN_IDS[isProduction ? "production" : "development"].basic
      .IDR_MONTHLY,
  },
  creator: {
    USD: PLAN_IDS[isProduction ? "production" : "development"].creator
      .USD_MONTHLY,
    GBP: PLAN_IDS[isProduction ? "production" : "development"].creator
      .GBP_MONTHLY,
    EUR: PLAN_IDS[isProduction ? "production" : "development"].creator
      .EUR_MONTHLY,
    INR: PLAN_IDS[isProduction ? "production" : "development"].creator
      .INR_MONTHLY,
    IDR: PLAN_IDS[isProduction ? "production" : "development"].creator
      .IDR_MONTHLY,
  },
  power: {
    USD: COMPLETE_PRICE_IDS[isProduction ? "production" : "development"][
      "Jammable Power User"
    ].usd,
    GBP: COMPLETE_PRICE_IDS[isProduction ? "production" : "development"][
      "Jammable Power User"
    ].gbp,
    EUR: COMPLETE_PRICE_IDS[isProduction ? "production" : "development"][
      "Jammable Power User"
    ].eur,
    INR: COMPLETE_PRICE_IDS[isProduction ? "production" : "development"][
      "Jammable Power User"
    ].inr,
    IDR: COMPLETE_PRICE_IDS[isProduction ? "production" : "development"][
      "Jammable Power User"
    ].idr,
  },
};

/**
 * Price mapping for each tier and currency
 * This makes it easy to add new currencies or adjust prices
 */
export const PRICE_MAP: Record<PriceTier, Record<CurrencyCode, number>> = {
  basic: {
    USD: 1.99,
    GBP: 1.99,
    EUR: 1.99,
    INR: 99,
    IDR: 8999,
  },
  "basic-discount": {
    USD: 7.99,
    GBP: 7.99,
    EUR: 7.99,
    INR: 399,
    IDR: 35000,
  },
  creator: {
    USD: 9.99,
    GBP: 9.99,
    EUR: 9.99,
    INR: 349,
    IDR: 29999,
  },
  "creator-discount": {
    USD: 24.99,
    GBP: 24.99,
    EUR: 24.99,
    INR: 1249,
    IDR: 100000,
  },
  power: {
    USD: 89.99,
    GBP: 89.99,
    EUR: 89.99,
    INR: 4499,
    IDR: 1349999,
  },
};

/**
 * Gets the appropriate price ID for a specific plan and currency
 * Falls back to USD if the specified currency is not supported
 *
 * @param plan - The subscription plan type
 * @param currency - The currency code
 * @returns The corresponding price ID
 */
export function getPriceId(plan: PlanType, currency: string): string {
  const currencyCode = currency as CurrencyCode;
  // Check if the currency exists in our mapping, otherwise default to USD
  return PLAN_PRICE_IDS[plan][currencyCode] || PLAN_PRICE_IDS[plan]["USD"];
}

/**
 * Gets the appropriate price ID by plan name and currency (lowercase)
 * For compatibility with the subscription API that uses plan names and lowercase currencies
 *
 * @param planName - The plan name (e.g., "Jammable Creator")
 * @param currency - The lowercase currency code (e.g., "usd")
 * @returns The corresponding price ID
 */
export function getPriceIdByNameAndCurrency(
  planName: PlanName,
  currency: LowercaseCurrencyCode,
): string {
  const env = isProduction ? "production" : "development";
  return COMPLETE_PRICE_IDS[env][planName][currency];
}

/**
 * Gets the appropriate price amount for a tier and currency
 * Falls back to USD if the specified currency is not supported
 *
 * @param currency - The currency code
 * @param tier - The price tier
 * @returns The price amount in the specified currency
 */
export function getPrice(currency: string, tier: string): number | null {
  // Check if the tier is valid
  if (!Object.keys(PRICE_MAP).includes(tier)) {
    return null;
  }

  const priceTier = tier as PriceTier;
  const currencyCode = currency as CurrencyCode;

  // If the currency exists in our mapping, return the price, otherwise default to USD
  return PRICE_MAP[priceTier][currencyCode] || PRICE_MAP[priceTier]["USD"];
}

/**
 * Gets the symbol for a given currency code
 * Falls back to $ if the currency is not supported
 *
 * @param currency - The currency code
 * @returns The currency symbol
 */
export function getCurrencySymbol(currency: string): string {
  const currencyCode = currency as CurrencyCode;
  return CURRENCIES[currencyCode]?.symbol || CURRENCIES["USD"].symbol;
}

/**
 * Format a price with the appropriate currency symbol
 *
 * @param amount - The price amount
 * @param currency - The currency code
 * @returns Formatted price with symbol
 */
export function formatPrice(amount: number, currency: string): string {
  const currencyCode = currency as CurrencyCode;
  const currencyInfo = CURRENCIES[currencyCode] || CURRENCIES["USD"];

  return currencyInfo.symbolPosition === "after"
    ? `${amount}${currencyInfo.symbol}`
    : `${currencyInfo.symbol}${amount}`;
}

/**
 * Gets the appropriate discount code for a price ID and currency
 *
 * @param priceId - The price ID to check
 * @param currency - The currency code
 * @returns The corresponding discount code or null if not found
 */
export function getDiscountCode(
  priceId: string,
  currency: string,
): string | null {
  const currencyCode = currency as CurrencyCode;
  const env = isProduction ? "production" : "development";

  // Determine plan type based on price ID
  let planType: PlanType | null = null;

  if (basicPlanId.includes(priceId)) {
    planType = "basic";
  } else if (creatorPlanId.includes(priceId)) {
    planType = "creator";
  }

  // Return null if not a recognized plan type
  if (!planType) return null;

  // Get the discount code, falling back to USD if the currency is not supported
  return (
    DISCOUNT_CODES[env][planType][currencyCode] ||
    DISCOUNT_CODES[env][planType]["USD"]
  );
}

/**
 * Converts uppercase currency code to lowercase for API compatibility
 *
 * @param currency - The uppercase currency code (e.g., "USD")
 * @returns The lowercase currency code (e.g., "usd")
 */
export function currencyToLowercase(currency: string): LowercaseCurrencyCode {
  return currency.toLowerCase() as LowercaseCurrencyCode;
}

/**
 * Converts lowercase currency code to uppercase for internal use
 *
 * @param currency - The lowercase currency code (e.g., "usd")
 * @returns The uppercase currency code (e.g., "USD")
 */
export function currencyToUppercase(currency: string): CurrencyCode {
  return currency.toUpperCase() as CurrencyCode;
}

/**
 * Format a price with proper locale-specific formatting
 * This is especially important for currencies like IDR that need thousands separators
 *
 * @param amount - The price amount
 * @param currency - The currency code
 * @returns Formatted price with proper locale formatting
 */
export function formatPriceWithLocale(
  amount: number,
  currency: string,
): string {
  const currencyCode = currency.toUpperCase() as CurrencyCode;

  // Use proper locale for the currency
  const locale = currencyCode === "IDR" ? "id-ID" : "en-US";

  // For IDR, we don't want decimal places
  const options: Intl.NumberFormatOptions = {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: currencyCode === "IDR" ? 0 : 2,
    maximumFractionDigits: currencyCode === "IDR" ? 0 : 2,
  };

  return new Intl.NumberFormat(locale, options).format(amount);
}
