import { loadStripe } from "@stripe/stripe-js";
import styles from "../../styles/Pricing.module.css";
import StripePaymentElement from "../StripePaymentElement";
import { Elements } from "@stripe/react-stripe-js";
import { formatPriceWithLocale, getPrice } from "../../utils/pricing";
import { Dialog, DialogContent } from "@jammable/ui-components/Dialog";

export const CreditCheckout = (props) => {
  const { newLayout, clientSecret, symbol, currency, subType, price } = props;

  const stripePromise = loadStripe(
    process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  );

  const stripeoptions = {
    clientSecret: clientSecret,
    loader: "always",
    fonts: [
      { cssSrc: "https://fonts.googleapis.com/css?family=Montserrat" },

      {
        cssSrc:
          "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600",
      },
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Montserrat&display=swap",
      },
    ],

    appearance: {
      variables: {
        fontLineHeight: "1",
        borderRadius: "16px",
        colorBackground: "#F6F8FA",
        colorPrimaryText: "#262626",
        fontSizeBase: "12px",
        colorPrimary: "#6744ce",
        fontFamily: "Montserrat",
      },
      theme: "flat",
      labels: "floating",
      rules: {
        ".Block": {
          backgroundColor: "var(--colorBackground)",
          boxShadow: "none",
          padding: "12px",
        },
        ".Input": {
          padding: "12px",
          fontWeight: "500",
        },

        ".Input:disabled, .Input--invalid:disabled": {
          color: "white",
        },
        ".Tab": {
          padding: "10px 12px 8px 12px",
          border: "none",
          fontSize: "14px",
        },
        ".Tab:hover": {
          border: "none",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
        },
        ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
          border: "none",
          backgroundColor: "#fff",
          boxShadow:
            "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
        },
        ".Label": {
          fontWeight: "400",
        },
        ".TermsText": {
          // display: "none",
          opacity: "0 !important",
          fontSize: 0,
        },
      },
    },
  };
  return (
    <>
      {/* <div
        style={{
          fontSize: 15,
          backgroundColor: "#E5E7EA",
          borderRadius: 21,
          marginBottom: 10,
          paddingInline: 20,
          fontWeight: 600,
          // flexDirection: "column",
          paddingTop: 13,
          paddingBottom: 13,
        }}
      >
        Total checkout price is <span style={{ fontWeight: 700 }}>{price}</span>
      </div> */}
      <Elements stripe={stripePromise} options={stripeoptions}>
        <StripePaymentElement
          newLayout={newLayout}
          price={price}
          currency={currency}
        />
      </Elements>
    </>
  );
};

const CreditCheckoutModal = ({ opened, setOpened, ...props }) => {
  return (
    <Dialog
      onOpenChange={(open) => setOpened(open)}
      preventClose
      open={opened}
      aria-hidden={opened ? false : true}
    >
      <DialogContent className="tw-w-full md:tw-min-w-[400px]">
        <div className={`vertical-flex-10`}>
          <div className="tw-text-2xl tw-font-bold">Checkout</div>
          <CreditCheckout {...props} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreditCheckoutModal;
