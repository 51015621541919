import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import firebase_app from "../../utils/firebase";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { useRouter } from "next/router";
import { usePathname } from "next/navigation";
import {
  GoogleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
} from "firebase/auth";

import toast from "react-hot-toast";
import {
  customGoogleErrorMessages,
  customSignInErrorMessages,
} from "../../utils/tools";
import axios from "axios";
import { useAuth } from "../../context/AuthProvider";
import { Lock, Message } from "react-iconly";
import { useModal } from "../../context/ModalContext";
import logo from "../../assets/logo.png";
import Image from "next/image";
import Link from "next/link";
import { FcGoogle } from "react-icons/fc";
import { Button, SecondaryButton } from "@jammable/ui-core";
import { Spinner } from "@jammable/ui-components/Loader";
import { Input } from "@jammable/ui-components/Forms";

const SignInModal = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const auth = getAuth(firebase_app);
  const router = useRouter();
  const pathname = usePathname();
  const { closeModal } = useModal();
  const { updateUser } = useAuth();

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // Handle your login logic here
      setLoading(true);
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then(() => {
          router.push(pathname);
          closeModal("sign-in-modal");
          toast.success("Welcome back!");
        })
        .catch((error) => {
          setError(customSignInErrorMessages[error.code] || error.message);
        })
        .finally(() => {
          setLoading(false);
        });
      // Redirect or update state as needed
    },
  });

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);

      const details = getAdditionalUserInfo(result);
      if (!details) {
        await result.user.delete();
        toast.error("An error occurred during registration. Please try again.");
        throw new Error("Additional user information not available");
      }

      if (details?.isNewUser) {
        axios
          .post("/api/auth/register", {
            user: { ...result?.user, username: result?.user?.displayName },
          })
          .then((res) => {
            updateUser({ avatar: res?.data?.user?.avatar });
            closeModal("sign-in-modal");
            toast.success("Welcome to Jammable!");
            router.push(pathname);
          })
          .catch(async (err) => {
            if (!err?.response?.data?.isDeleted) {
              await result.user.delete();
            }
            if (err.response.data.message) {
              setError(err.response.data.message);
            } else {
              toast.error(
                "An error occurred during registration. Please try again.",
              );
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        closeModal("sign-in-modal");
        toast.success("Welcome back!");
        router.push(pathname);
      }
    } catch (error) {
      setError(customGoogleErrorMessages[error.code] || error.message);
      setLoading(false);
    }
  };

  const errorStyle =
    "tw-text-red-400 tw-font-bold tw-text-sm tw-mt-1 tw-text-left tw-mb-0.5";
  const inputStyle =
    "tw-w-full tw-rounded-2xl tw-transition-all tw-h-12 md:tw-h-14 tw-text-sm md:tw-text-base tw-bg-gray-50 tw-border-gray-100 tw-border-2 tw-border-solid focus:tw-border-gray-200";
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-p-1">
      <Image
        quality={100}
        width={60}
        height={60}
        src={logo}
        alt="Jammable Logo"
      />

      <div className="tw-text-2xl tw-font-black tw-tracking-tight tw-mt-1 tw-text-center">
        Sign in to Jammable
      </div>

      <SecondaryButton
        type="button"
        onClick={handleGoogleSignIn}
        className="tw-w-full tw-rounded-full"
      >
        <FcGoogle size={25} />
        Continue with Google
      </SecondaryButton>
      <div className="tw-font-black tw-text-sm tw-py-1 md:tw-py-2 tw-gap-3 tw-tracking-widest tw-w-full tw-flex tw-items-center tw-text-center tw-justify-center">
        <div className="tw-bg-gray-100 tw-h-0.5 tw-w-full" />
        OR
        <div className="tw-bg-gray-100 tw-h-0.5 tw-w-full" />
      </div>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 15,
          width: "100%",
        }}
        onChange={() => {
          setError(null);
        }}
        onSubmit={formik.handleSubmit}
      >
        {error && (
          <div className="tw-bg-red-500 tw-text-white tw-text-sm tw-text-center tw-p-2 tw-rounded-2xl tw-font-bold">
            {error}
          </div>
        )}
        <div>
          {formik.touched.email && formik.errors.email && (
            <div className={errorStyle}>{formik.errors.email}</div>
          )}
          <Input
            placeholder="Email"
            bordered
            fullWidth
            size="lg"
            color="primary"
            contentLeft={<Message set="light" />}
            name="email"
            status={formik.errors.email ? "error" : ""}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && !!formik.errors.email}
            aria-label="Email Input"
            className={inputStyle}
          />
        </div>
        <div>
          {formik.touched.password && formik.errors.password && (
            <div className={errorStyle}>{formik.errors.password}</div>
          )}
          <Input
            placeholder="Password"
            bordered
            fullWidth
            size="lg"
            color="primary"
            contentLeft={<Lock fill="black" />}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && !!formik.errors.password}
            aria-label="Password Input"
            className={inputStyle}
            type="password"
          />
        </div>
        <Link
          href="/forgotpassword"
          color="white"
          className="tw-text-purple-700 tw-text-sm tw-font-bold"
        >
          Forgot Password?
        </Link>
        <Button
          fullWidth
          flat
          type="submit"
          theme="light"
          disabled={loading}
          aria-label="Sign In Button"
          className="tw-rounded-full"
        >
          {loading ? (
            <Spinner size={4} className="tw-border-white tw-border-3" />
          ) : (
            "Sign In"
          )}
        </Button>

        <div className="tw-font-semibold tw-text-center tw-text-sm">
          Don&apos;t have an account?{" "}
          <Link
            className="tw-text-purple-700 tw-font-black tw-underline"
            href={`/signup`}
          >
            Sign up for free!
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SignInModal;
