type Currency = "GBP" | "USD" | "EUR" | "INR" | "IDR";

export function getLocale() {
  return "en-US";
}

export function formatCurrency(amount: number, currency: Currency) {
  const formatter = new Intl.NumberFormat(getLocale(), {
    style: "currency",
    currency,
  });
  return formatter.format(amount / 100);
}

export function getCurrencySymbol(currency: Currency): string {
  return (0)
    .toLocaleString(getLocale(), {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
}
