import { useEffect, useState } from "react";
import {
  AvailableUpgrade,
  Currency,
  SubscriptionUpgradeType,
  UpgradeOptionsResponse,
  fetchSubscriptionUpgradeOptions,
  mutationCreateSubscriptionIntent,
  mutationSubscriptionUpgrade,
} from "@jammable/clientapi/src/payments";
import { toast } from "@jammable/ui-core";
import { getCurrencySymbol } from "../utils";

export interface UseUpgradeOptionsProps {
  type?: SubscriptionUpgradeType;
  interval?: "month" | "year";
  fallback?: () => void;
  callback?: () => void;
  from?: string;
}

export function useUpgradeOptions({
  type,
  interval = "month",
  callback,
  fallback,
  from,
}: UseUpgradeOptionsProps) {
  const [upgradeOptions, setUpgradeOptions] =
    useState<UpgradeOptionsResponse>();
  const [isUpgrading, setIsUpgrading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<AvailableUpgrade>();
  const [showCheckout, setShowChekout] = useState(false);
  const [checkoutParams, setcheckoutParams] = useState<{
    clientSecret: string;
    priceId: string;
    subType: string;
    currency: Currency;
    symbol: string;
    price: number;
  }>();

  const currency =
    upgradeOptions?.subscription?.currency ||
    upgradeOptions?.availableUpgrades[0].currency;

  const handleSelectPlan = (value: string) => {
    const plan = upgradeOptions?.availableUpgrades.find(
      (curr) => curr.product.name === value,
    );
    setSelectedPlan(plan);
  };

  useEffect(() => {
    const fetchUpgradeOptions = async () => {
      try {
        setUpgradeOptions(await fetchSubscriptionUpgradeOptions());
      } catch (e) {
        fallback?.();
      }
    };
    fetchUpgradeOptions();
  }, [fallback]);

  useEffect(() => {
    if (upgradeOptions?.availableUpgrades)
      setSelectedPlan(upgradeOptions.availableUpgrades[0]);
  }, [upgradeOptions]);

  const handleUpgrade = async () => {
    try {
      setIsUpgrading(true);
      await mutationSubscriptionUpgrade(type);
      callback?.();
    } catch (e) {
      fallback?.();
    }
  };

  const handleNewSubscription = async () => {
    try {
      setIsUpgrading(true);
      const intent = await mutationCreateSubscriptionIntent({
        priceId: selectedPlan!.id,
        currency: currency!.toUpperCase() as Currency,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        refId: (window as any).tolt_referral,
        from: from,
      });
      setcheckoutParams({
        clientSecret: intent.clientSecret,
        priceId: selectedPlan!.id,
        subType: selectedPlan!.type,
        currency: currency!.toUpperCase() as Currency,
        symbol: getCurrencySymbol(currency!.toUpperCase() as Currency),
        price:
          selectedPlan!.discountedAmount && selectedPlan!.discountedAmount > 0
            ? selectedPlan!.discountedAmount / 100
            : selectedPlan!.amount / 100,
      });
      setShowChekout(true);
    } catch (e) {
      setIsUpgrading(false);
      toast.error("Something went wrong, please try again later");
      fallback?.();
    }
  };

  const upgrade: AvailableUpgrade | null = type
    ? upgradeOptions?.availableUpgrades?.find(
        (option) =>
          option.product?.name === type && option.plan?.interval === interval,
      ) || null
    : null;

  return {
    isLoadingPrices: !upgradeOptions,
    isUpgrading,
    currency,
    upgradeOptions: upgradeOptions?.availableUpgrades,
    upgradeOfType: upgrade,
    selectedPlan,
    selectedPlanName: selectedPlan?.product.name,
    handleSelectPlan,
    handleUpgrade,
    handleNewSubscription,
    showCheckout,
    checkoutParams,
  };
}
