import axios from "axios";
import { getAuth } from "firebase/auth";

/**
 * Fetches notifications for the current user
 */
export const fetchNotifications = async ({
  limit = 10,
  offset = 0,
}: {
  limit?: number;
  offset?: number;
} = {}): Promise<any> => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`/api/notification/getNotifications`, {
      params: { limit, offset },
      headers: { Authorization: token },
    });

    // Include the offset in the response for pagination tracking
    return {
      ...response.data,
      offset: offset,
    };
  } catch (error) {
    console.error("Error fetching notifications:", error);
    return {
      notifications: [],
      totalCount: 0,
      unreadCount: 0,
      hasMore: false,
      offset: offset,
    };
  }
};

/**
 * Marks notifications as read
 */
export const markNotificationsAsRead = async ({
  ids,
  all = false,
}: {
  ids?: string[];
  all?: boolean;
}): Promise<any> => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `/api/notification/markAsRead`,
      { ids, all },
      {
        headers: { Authorization: token },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error marking notifications as read:", error);
    return { updatedCount: 0, unreadCount: 0 };
  }
};
