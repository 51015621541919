import { useInfiniteQuery, useMutation } from "react-query";
import {
  fetchNotifications,
  markNotificationsAsRead,
} from "../utils/notifications";
import { useAuth } from "../context/AuthProvider";

const LIMIT = 10;

export function useNotificationsData() {
  const { user } = useAuth();

  // Main notifications query with infinite pagination
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ["notifications", user?.uid],
    ({ pageParam = 0 }) =>
      fetchNotifications({ limit: LIMIT, offset: pageParam }),
    {
      enabled: !!user?.uid,
      refetchInterval: 30000, // Poll every 30 seconds
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) => {
        if (!lastPage.hasMore) return undefined;
        // Calculate next offset based on current number of loaded notifications
        return lastPage.notifications.length > 0
          ? lastPage.offset + lastPage.notifications.length
          : undefined;
      },
    },
  );

  // Mark notifications as read mutation
  const markAsReadMutation = useMutation(
    (ids: string[]) => markNotificationsAsRead({ ids }),
    {
      onSuccess: () => {
        refreshNotifications();
      },
    },
  );

  // Mark all notifications as read mutation
  const markAllAsReadMutation = useMutation(
    () => markNotificationsAsRead({ all: true }),
    {
      onSuccess: () => {
        refreshNotifications();
      },
    },
  );

  const refreshNotifications = async () => {
    if (!user) return;
    await refetch();
  };

  const loadMoreNotifications = async () => {
    if (!user || isFetchingNextPage || !hasNextPage) return;
    await fetchNextPage();
  };

  const markAsRead = async (ids?: string[]) => {
    if (!user || !ids?.length) return;
    await markAsReadMutation.mutateAsync(ids);
  };

  const markAllAsRead = async () => {
    if (!user) return;
    await markAllAsReadMutation.mutateAsync();
  };

  // Combine all pages of notifications
  const notifications = data?.pages.flatMap((page) => page.notifications) || [];

  // Get unreadCount and totalCount from the latest page
  const latestPage = data?.pages[0];

  return {
    notifications,
    unreadCount: latestPage?.unreadCount || 0,
    totalCount: latestPage?.totalCount || 0,
    hasMore: !!hasNextPage,
    loading: isLoading,
    isFetchingNextPage,
    refreshNotifications,
    loadMoreNotifications,
    markAsRead,
    markAllAsRead,
  };
}
