import React, { useEffect, useState } from "react";
import { getRandomAnimal } from "../utils/tools";
import { SecondaryButton } from "@jammable/ui-core";
import Link from "next/link";

const NotFound = ({
  title = "No voices found...",
  description,
  buttonText,
  buttonLink ,
  className = "",
  animateIn = true,
}) => {
  const [isVisible, setIsVisible] = useState( animateIn ? false : true);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`tw-flex tw-text-center tw-flex-col tw-text-3xl tw-font-black tw-justify-center tw-items-center tw-w-full tw-h-full ${className} ${
        isVisible
          ? "tw-opacity-100 tw-translate-y-0 "
          : "tw-opacity-0 tw-translate-y-10"
      } tw-transition-all tw-duration-500 tw-ease-in-out`}
    >
      <div className="tw-bg-purple-50 tw-border-2 tw-border-purple-200 tw-border-solid tw-text-purple-600 tw-mb-2 tw-flex tw-items-center tw-rounded-4xl tw-p-5 tw-w-max tw-h-max">
        {getRandomAnimal(50, 2)}
      </div>
      <div className="tw-text-3xl tw-font-black tw-tracking-tight">{title}</div>
      <div className="tw-text-base tw-text-black/40 tw-font-semibold tw-mt-2  tw-tracking-normal">
        {description}
      </div>
      {buttonText && buttonLink && (
        <SecondaryButton
          as={Link}
        href={buttonLink}
        className="tw-mt-3 tw-rounded-full tw-tracking-normal tw-text-base"
      >
        {buttonText}
        </SecondaryButton>
      )}
    </div>
  );
};

export default NotFound;
