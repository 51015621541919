// hooks/useAuthModal.js
import { useModal } from "../context/ModalContext";
import firebase_app from "../utils/firebase";
import { getAuth } from "firebase/auth";
import SignInModal from "../components/modals/SignInModal";

const useAuthModal = () => {
  const auth = getAuth(firebase_app);
  const { openModal, closeModal } = useModal();

  const openSignInModal = () => {
    openModal(
      "sign-in-modal",
      <SignInModal closeModal={() => closeModal("sign-in-modal")} />
    );
  };

  const checkAuthAndExecute = () => {
    const user = auth.currentUser;
    if (user) {
      return true;
    } else {
      openSignInModal();
      return false;
    }
  };

  return { checkAuthAndExecute };
};

export default useAuthModal;
