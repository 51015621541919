import { UpgradeOptionsResponse } from "@jammable/clientapi/src/payments";
import {
  RadioGroup,
  RadioGroupItem,
} from "@jammable/ui-core/src/primitives/RadioGroup";
import { Button, cn } from "@jammable/ui-core";
import { FaCheck, FaHeart, FaMusic, FaStar } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { CreditCheckout } from "../../../../../../../apps/web/components/modals/CreditCheckoutModal";
import { Spinner } from "@jammable/ui-components/Loader";
import { formatPriceWithLocale } from "../../../../../../../apps/web/utils/pricing";
import { useEffect, useState } from "react";
import { TbMusicStar } from "react-icons/tb";
import { FcMusic } from "react-icons/fc";
import { IoIosMusicalNote } from "react-icons/io";

export function PlanSelector({
  upgradeOptions,
  selectedPlanName,
  currency,
  handleSelectPlan,
  hasSidebar,
  handleNewSubscription,
  showCheckout,
  checkoutParams,
  isUpgrading,
  isLoadingPrices,
  message,
}: {
  selectedPlanName?: string;
  currency?: "GBP" | "USD" | "EUR" | "INR";
  upgradeOptions?: UpgradeOptionsResponse["availableUpgrades"];
  handleSelectPlan: (value: string) => void;
  hasSidebar?: boolean;
  showCheckout?: boolean;
  checkoutParams?: Record<string, unknown>;
  handleNewSubscription: () => void;
  isUpgrading?: boolean;
  isLoadingPrices?: boolean;
  message?: { message?: string; item?: string };
}) {
  return showCheckout && checkoutParams ? (
    <div className="tw-w-full tw-p-5">
      <h2 className="tw-m-0 tw-mb-4 tw-text-3xl tw-font-black">Checkout</h2>
      <div>
        <CreditCheckout newLayout {...checkoutParams} />
      </div>
    </div>
  ) : (
    <div className={`tw-relative ${!upgradeOptions ? "tw-w-full" : ""}`}>
      {/* TikTok Sticker */}
      <div
        className="tw-absolute tw-z-10 tw-right-8 tw--top-8 tw-transform  tw-rotate-6"
        style={{
          filter: "drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))",
          zIndex: 100000,
        }}
      >
        <div className="tw-bg-black tw-text-white tw-rounded-full tw-px-4 tw-py-2 tw-flex tw-items-center tw-gap-2 tw-font-bold tw-text-sm tw-shadow-md ">
          <FaTiktok className="tw-text-lg" />
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <span className="tw-text-xs">As Seen on TikTok</span>
            <span className="tw-text-xs tw-opacity-60">2B+ Views</span>
          </div>
        </div>
      </div>

      <RadioGroup
        className={`tw-flex tw-w-full  tw-flex-col tw-gap-3 tw-rounded-3xl tw-bg-gray-100/80 tw-p-5 tw-shadow-sm tw-pt-5`}
        defaultValue={upgradeOptions?.[0]?.product.name}
        onValueChange={handleSelectPlan}
      >
        <div className="tw-mb-2">
          <div
            className={`tw-text-xl tw-font-black ${
              message?.message && "tw-hidden"
            } md:tw-flex tw-items-center tw-gap-2`}
          >
            <span className="tw-text-purple-700  tw-text-2xl">
              Choose Plan to Continue!
            </span>
          </div>
          <div className="tw-text-sm tw-font-semibold tw-text-black/40">
            Join thousands of creators on a Jammable plan!
          </div>
        </div>
        {upgradeOptions ? (
          upgradeOptions?.map(
            ({
              id,
              title,
              description,
              amount,
              mostPopular,
              percentageDiscount,
              discountedAmount,
              plan,
              product,
            }) =>
              title !== "Power" && (
                <div
                  key={id}
                  className={`${hasSidebar ? `md:tw-w-[470px]` : "tw-w-full "}`}
                >
                  <div
                    key={id}
                    className={cn(
                      "tw-relative tw-m-0 tw-rounded-3xl tw-bg-white tw-p-1 tw-transition-all tw-duration-300 md:tw-min-w-96 tw-border-2 tw-border-transparent",
                      {
                        "tw-bg-purple-100 tw-text-black tw-scale-[1.01] tw-shadow-md tw-border-purple-200":
                          selectedPlanName === product.name,
                      },
                    )}
                  >
                    <RadioGroupItem
                      id={id}
                      value={product.name}
                      checked={selectedPlanName === product.name}
                      className={cn("tw-absolute tw-right-3 tw-top-3", {
                        "tw-border-white": selectedPlanName === product.name,
                      })}
                    />
                    <label
                      htmlFor={id}
                      className="tw-block tw-h-full tw-cursor-pointer tw-px-4 tw-py-3 tw-text-left"
                    >
                      <div className="tw-flex tw-font-black tw-mb-2 tw-text-black tw-items-center">
                        <span className="tw-text-lg">{title}</span>
                        {percentageDiscount && (
                          <div className="tw-ml-1 tw-inline-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-4xl tw-bg-purple-700 tw-px-1.5 tw-py-1">
                            <div className="tw-text-xs tw-font-black tw-uppercase tw-text-white tw-flex tw-items-center tw-gap-1">
                              <FaStar className="tw-text-yellow-300" />
                              {`-${percentageDiscount}%`} First Month
                            </div>
                          </div>
                        )}
                        {mostPopular && (
                          <div
                            style={{
                              background:
                                "linear-gradient(45deg, rgba(140,55,213,0.95) 5%, rgba(242,79,225,1) 30%, rgba(255,0,0,0.9) 100%)",
                            }}
                            className="tw-ml-1 tw-inline-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-4xl tw-bg-gradient-to-l tw-from-pink-500 tw-to-purple-600 tw-px-1.5 tw-py-1"
                          >
                            <div className="tw-flex tw-items-center tw-gap-1 tw-px-1 tw-text-xs tw-font-black tw-uppercase tw-text-white">
                              <FaHeart />
                              <span className="tw-hidden md:tw-flex">
                                {" "}
                                Most
                              </span>{" "}
                              Popular
                            </div>
                          </div>
                        )}
                      </div>
                      {discountedAmount ? (
                        <div>
                          <div className="tw-font-semibold tw-line-through tw-opacity-40">
                            {`${formatPriceWithLocale(amount / 100, currency!)}`}
                          </div>
                          <div>
                            <span className="tw-text-3xl tw-font-black">
                              {currency && discountedAmount
                                ? formatPriceWithLocale(
                                    discountedAmount / 100,
                                    currency,
                                  )
                                : ""}{" "}
                            </span>
                            <span className="tw-font-semibold"> /month.</span>
                          </div>
                        </div>
                      ) : (
                        <div className="tw-font-semibold">
                          <span className="tw-font-bold">
                            {" "}
                            {`${formatPriceWithLocale(amount / 100, currency!)}`}{" "}
                          </span>{" "}
                          {`per ${plan.interval}.`}
                        </div>
                      )}

                      <div className="tw-mt-1.5 tw-flex tw-flex-wrap tw-gap-2 tw-text-sm tw-font-semibold">
                        {description?.map((desc) => {
                          return (
                            <div
                              key={desc.title}
                              className={`tw-p-1.5 tw-px-2 tw-rounded-lg ${desc.color} tw-text-sm tw-font-black tw-uppercase tw-text-black tw-tracking-wide tw-flex tw-items-center tw-transition-all tw-duration-200 hover:tw-opacity-90`}
                            >
                              {desc.title}
                            </div>
                          );
                        })}
                      </div>
                    </label>
                  </div>
                </div>
              ),
          )
        ) : (
          <div className="">
            <div className="tw-mx-auto tw-mb-4 tw-flex tw-h-32 tw-w-full tw-animate-pulse tw-flex-col tw-items-start tw-justify-evenly tw-rounded-2xl tw-border-2 tw-border-solid tw-border-gray-100 tw-bg-white tw-p-4 tw-py-2 sm:tw-min-w-[454px]">
              <div className="tw-h-4 tw-w-48 tw-animate-pulse tw-rounded-2xl tw-bg-gray-100"></div>
              <div className="tw-h-6 tw-w-full tw-animate-pulse tw-rounded-2xl tw-bg-gray-100"></div>
            </div>
            <div className="tw-mx-auto tw-mb-4 tw-flex tw-h-32 tw-w-full tw-animate-pulse tw-flex-col tw-items-start tw-justify-evenly tw-rounded-2xl tw-border-2 tw-border-solid tw-border-gray-100 tw-bg-white tw-p-4 tw-py-2 sm:tw-min-w-[454px]">
              <div className="tw-h-4 tw-w-48 tw-animate-pulse tw-rounded-2xl tw-bg-gray-100"></div>
              <div className="tw-h-6 tw-w-full tw-animate-pulse tw-rounded-2xl tw-bg-gray-100"></div>
            </div>

            <span className="tw-sr-only">Loading...</span>
          </div>
        )}
        <Button
          style={{
            fontSize: 17,
          }}
          onClick={handleNewSubscription}
          className="tw-rounded-full tw-h-14"
          disabled={isUpgrading || isLoadingPrices}
        >
          {isUpgrading ? (
            <Spinner className="tw-border-white tw-border-3" size={4} />
          ) : (
            "Try it Now!"
          )}
        </Button>
      </RadioGroup>
    </div>
  );
}
