import React, { useState, useRef, useEffect } from "react";
import { useRouter } from "next/router";
import { formatDistanceToNow } from "date-fns";
import { Spinner } from "@jammable/ui-components/Loader";
import { MdNotifications } from "react-icons/md";
import { SecondaryButton } from "@jammable/ui-core";
import Image from "next/image";
import NotFound from "../NotFound";
import logo from "../../assets/logo.png";
import { useNotificationsData } from "../../hooks/useNotificationsData";

const NotificationItem = ({ notification, onClose }) => {
  const router = useRouter();
  const { markAsRead } = useNotificationsData();

  const handleClick = async () => {
    if (!notification.isRead) {
      await markAsRead([notification._id]);
    }
    onClose();
    if (notification.link) {
      router.push(notification.link);
    }
  };

  // Get notification type icon

  // Determine notification avatar
  const getNotificationIcon = () => {
    if (
      notification.sourceUserAvatar &&
      notification.sourceType !== "collection"
    ) {
      return (
        <Image
          src={notification.sourceUserAvatar}
          alt={notification.sourceUserName || "User"}
          className="tw-w-10 tw-h-10 tw-rounded-full tw-object-cover tw-border-2 tw-border-white tw-shadow-sm"
          width={40}
          height={40}
        />
      );
    }

    if (notification.type === "system") {
      return (
        <div className="tw-w-10 tw-h-10 tw-rounded-xl tw-bg-purple-600 tw-flex tw-items-center tw-justify-center">
          <Image src={logo} alt="Jammable" width={25} height={25} />
        </div>
      );
    }

    if (
      notification.type === "model_training_success" ||
      notification.type === "model_training_failed"
    ) {
      return (
        <Image
          src={notification.source.image.url}
          alt={notification.source.artistName || "Model"}
          className="tw-w-10 tw-h-10 tw-rounded-full tw-object-cover"
          width={20}
          height={20}
        />
      );
    }

    // if (notification.type === "model_training_failed") {
    //   return <MdLightbulb className={iconStyle} size={iconSize} />;
    // }

    // Choose background color based on notification type
    let bgClass = "tw-bg-purple-100";
    let iconClass = "tw-text-purple-700";

    switch (notification.type) {
      case "streak_milestone":
        return (
          <div className="tw-w-10 tw-h-10 tw-rounded-full tw-bg-green-100 tw-flex tw-items-center tw-justify-center">
            🎁
          </div>
        );
      case "voice_milestone":
        return (
          <Image
            src={notification?.source?.image?.url}
            alt={notification?.source?.artistName || "Model"}
            width={40}
            height={40}
            className="tw-rounded-full"
          />
        );
      case "collection_add":
        return (
          <div className="tw-relative tw-w-10 tw-h-10">
            <div className="tw-absolute  tw-bg-gray-200 tw-rounded-lg tw-shadow-sm tw--top-1 tw-w-[80%] tw-left-[10%] tw-h-10"></div>
            <Image
              src={notification?.source?.image?.url}
              alt={notification?.source?.name || "Collection"}
              width={40}
              height={40}
              className="tw-rounded-lg tw-relative tw-z-10"
            />
          </div>
        );
      case "comment":
        bgClass = "tw-bg-blue-100";
        iconClass = "tw-text-blue-700";
        break;
      case "like":
        bgClass = "tw-bg-pink-100";
        iconClass = "tw-text-pink-700";
        break;
      case "follow":
        bgClass = "tw-bg-green-100";
        iconClass = "tw-text-green-700";
        break;

      case "model_training_success":
        bgClass = "tw-bg-green-100";
        iconClass = "tw-text-green-700";
        break;
      case "model_training_failed":
      default:
        break;
    }

    return (
      <div
        className={`tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-rounded-full ${bgClass} tw-shadow-sm`}
      >
        <MdNotifications className={iconClass} size={20} />
      </div>
    );
  };

  return (
    <div
      className={`tw-p-3 tw-cursor-pointer tw-transition-all tw-duration-200 hover:tw-bg-gray-50 tw-animate-fadeIn ${
        notification?.isRead || notification?.type === "system"
          ? "tw-bg-white"
          : "tw-bg-purple-50"
      }`}
      onClick={handleClick}
    >
      <div className="tw-flex tw-items-start tw-gap-2">
        {getNotificationIcon()}

        <div className="tw-flex-1">
          <div className="tw-text-sm tw-font-medium tw-text-gray-900">
            {notification.type === "system" && (
              <span className="tw-text-purple-600 tw-text-xs tw-font-black tw-uppercase tw-bg-purple-100 tw-px-2 tw-py-1 tw-rounded-full tw-mr-1">
                JAMMABLE TEAM
              </span>
            )}
            <span dangerouslySetInnerHTML={{ __html: notification.message }} />
          </div>

          <div className="tw-flex tw-items-center tw-gap-1.5 tw-mt-1">
            <div className="tw-text-xs tw-text-black/40">
              {notification.createdAt &&
                formatDistanceToNow(new Date(notification.createdAt), {
                  addSuffix: true,
                })}
            </div>
          </div>
        </div>

        {!notification?.isRead && notification?.type !== "system" && (
          <div className="tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-purple-700 tw-mt-1 tw-shrink-0"></div>
        )}
      </div>
    </div>
  );
};

const NotificationIcon = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    notifications,
    unreadCount,
    loading,
    hasMore,
    loadMoreNotifications,
    markAllAsRead,
    isFetchingNextPage,
  } = useNotificationsData();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      markAllAsRead();
    }
  }, [isOpen]);

  return (
    <div className="tw-relative" ref={dropdownRef}>
      <button
        className="tw-relative tw-flex tw-items-center tw-justify-center  tw-rounded-full tw-bg-none tw-border-none tw-transition-colors tw-bg-white tw-cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        aria-label={`Notifications${unreadCount > 0 ? ` (${unreadCount} unread)` : ""}`}
      >
        <MdNotifications size={24} className="tw-text-black" />
        {unreadCount > 0 && (
          <span
            className={`tw-absolute tw--top-1 tw-right-0 tw-bg-purple-700 tw-text-white tw-rounded-full tw-font-black tw-p-1 tw-py-0.5 ${
              unreadCount > 9 ? "tw-px-1" : "tw-px-2"
            } tw-flex tw-items-center tw-justify-center tw-text-[10px] tw-normal-nums`}
          >
            {unreadCount > 99 ? "99+" : unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="tw-fixed md:tw-absolute tw-left-[2vw] md:tw-left-auto tw-right-auto md:tw-right-0 tw-transform tw--translate-x-1/2 md:tw-translate-x-0 tw-top-16 md:tw-top-auto md:tw-mt-2 tw-w-[94vw] md:tw-w-96 tw-bg-white tw-rounded-2xl tw-shadow-2xl tw-z-50 tw-max-h-[70vh] md:tw-max-h-[500px] tw-overflow-hidden tw-flex tw-flex-col tw-animate-slideDown tw-transform-origin-top">
          <div className="tw-p-3">
            <h3 className="tw-text-xl tw-tracking-wide tw-font-black tw-text-black tw-uppercase ">
              ACTIVITY
            </h3>
            <div className="tw-text-sm tw-text-black/40">
              Keep track of all your Jammable activity here!
            </div>
          </div>

          <div className="tw-h-px tw-w-full tw-bg-gray-100 " />

          <div className="tw-overflow-y-auto tw-flex-1 tw-scroll-smooth">
            {loading && !isFetchingNextPage ? (
              <div className="tw-p-8 tw-flex tw-items-center tw-justify-center">
                <Spinner size={4} className="tw-border-3" />
              </div>
            ) : notifications.length === 0 ? (
              <div className="tw-p-8 tw-flex tw-items-center tw-justify-center">
                <NotFound
                  title="No notifications yet"
                  description="We'll notify you when something happens!"
                />
              </div>
            ) : (
              <div className="tw-divide-y tw-divide-gray-100">
                {notifications.map((notification) => (
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                    onClose={() => setIsOpen(false)}
                  />
                ))}
              </div>
            )}

            {hasMore && (
              <div className="tw-w-full tw-flex tw-justify-center tw-my-2 tw-mb-2">
                <SecondaryButton
                  flat
                  className="tw-text-purple-700 tw-text-xs tw-rounded-full tw-w-max tw-py-2 hover:tw-bg-purple-50 tw-h-8 md:tw-px-4"
                  onClick={() => loadMoreNotifications()}
                  disabled={isFetchingNextPage}
                >
                  {isFetchingNextPage ? (
                    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
                      <Spinner size={4} className="tw-border-3" />
                      <span>Loading...</span>
                    </div>
                  ) : (
                    "Load more"
                  )}
                </SecondaryButton>
              </div>
            )}
          </div>
        </div>
      )}

      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes slideDown {
          from {
            opacity: 0;
            transform: translateY(10px) scale(0.95);
          }
          to {
            opacity: 1;
            transform: translateY(0) scale(1);
          }
        }

        :global(.tw-animate-fadeIn) {
          animation: fadeIn 0.2s ease-out forwards;
        }

        :global(.tw-animate-slideDown) {
          animation: slideDown 0.2s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default NotificationIcon;
