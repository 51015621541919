// hooks/useSubscription.js
import { useContext } from "react";
import { useAuth } from "../context/AuthProvider";
import { getSubscriptionStatus } from "../utils/tools";

const useSubscription = () => {
  const { user } = useAuth();

  const hasCreatorPlan = () => {
    return (
      user &&
      user.subscriptions &&
      getSubscriptionStatus(user.subscriptions) === "creator"
    );
  };

  const hasPowerPlan = () => {
    return (
      user &&
      user.subscriptions &&
      getSubscriptionStatus(user.subscriptions) === "power"
    );
  };

  return { hasCreatorPlan, hasPowerPlan };
};

export default useSubscription;
