import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@jammable/ui-components/Dialog";
import { FaArrowCircleUp } from "react-icons/fa";
import { Subscribe } from "../../../../packages/ui/features/payments/src/Subscribe";
import { primaryButtonStyles, toast } from "@jammable/ui-core";
import useAuthModal from "@/hooks/useAuthModal";

const UpgradeModal = ({ open, setOpen, from }) => {
  const [forceCloseSubModal, setForceCloseSubModal] = useState(false);

  const message = "";
  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open);
      }}
    >
      <DialogContent
        className="tw-p-0 tw-z-5000 tw-rounded-t-xl tw-overflow-visible"
        drawerMode
      >
        <Subscribe
          hasSidebar={open?.dailyLimit ? true : false}
          renderCheckout={true}
          open={open}
          message={message}
          callback={() => {
            toast.success("You have subscribed!");
          }}
          from={from}
        />
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeModal;
